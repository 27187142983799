import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(
    private http: HttpClient
  ) 
  { }

  public getAll(id_user:any, type_user:any):Observable<any> {
    const uri = `${environment.url_api}/clientes/${id_user}/${type_user}`;
    // console.log(uri);
    return this.http.get(`${environment.url_api}/clientes/${id_user}/${type_user}`)
  }


  public get(id_cliente:any, id_user:any, type_user:any):Observable<any> {
    //console.log("params service customer"+id)
    return this.http.get(`${environment.url_api}/direcciones/cliente/${id_cliente}/${id_user}/${type_user}`)
  }

  public observations(id_cliente:any):Observable<any> {
    //console.log("params service customer"+id)
    return this.http.get(`${environment.url_api}/observaciones/cliente/${id_cliente}`)
  }

  public priorities():Observable<any> {
    return this.http.get(`${environment.url_api}/prioridades/entrega`)
  }

  public tranportistas():Observable<any> {
    return this.http.get(`${environment.url_api}/transportistas`)
  }

  public getBloqueoCliente(id_cliente:any):Observable<any>{
    // console.log(id_cliente)
    return this.http.get(`${environment.url_api}/cliente/bloqueo/${id_cliente}`)
  }

  public getBloqueosRutas(): Observable<any>{
    return this.http.get(`${environment.url_api}/bloqueo/rutas`)
  }

}
